import { TrendsData, TrendsSEOTable } from "utils/types";

/* To update trends change this date, deploy, then purge/invalidate /trends paths */
export const TRENDS_LAST_MODIFIED = "2025-03-01";

export const statePaths = [
	{
		name: "Alabama",
		abbreviation: "AL",
		path: "alabama",
		state_fips: "01",
	},
	{
		name: "Alaska",
		abbreviation: "AK",
		path: "alaska",
		state_fips: "02",
	},
	{
		name: "Arizona",
		abbreviation: "AZ",
		path: "arizona",
		state_fips: "04",
	},
	{
		name: "Arkansas",
		abbreviation: "AR",
		path: "arkansas",
		state_fips: "05",
	},
	{
		name: "California",
		abbreviation: "CA",
		path: "california",
		state_fips: "06",
	},
	{
		name: "Colorado",
		abbreviation: "CO",
		path: "colorado",
		state_fips: "08",
	},
	{
		name: "Connecticut",
		abbreviation: "CT",
		path: "connecticut",
		state_fips: "09",
	},
	{
		name: "Delaware",
		abbreviation: "DE",
		path: "delaware",
		state_fips: "10",
	},
	{
		name: "District of Columbia",
		abbreviation: "DC",
		path: "district-of-columbia",
		state_fips: "11",
	},
	{
		name: "Florida",
		abbreviation: "FL",
		path: "florida",
		state_fips: "12",
	},
	{
		name: "Georgia",
		abbreviation: "GA",
		path: "georgia",
		state_fips: "13",
	},
	{
		name: "Hawaii",
		abbreviation: "HI",
		path: "hawaii",
		state_fips: "15",
	},
	{
		name: "Idaho",
		abbreviation: "ID",
		path: "idaho",
		state_fips: "16",
	},
	{
		name: "Illinois",
		abbreviation: "IL",
		path: "illinois",
		state_fips: "17",
	},
	{
		name: "Indiana",
		abbreviation: "IN",
		path: "indiana",
		state_fips: "18",
	},
	{
		name: "Iowa",
		abbreviation: "IA",
		path: "iowa",
		state_fips: "19",
	},
	{
		name: "Kansas",
		abbreviation: "KS",
		path: "kansas",
		state_fips: "20",
	},
	{
		name: "Kentucky",
		abbreviation: "KY",
		path: "kentucky",
		state_fips: "21",
	},
	{
		name: "Louisiana",
		abbreviation: "LA",
		path: "louisiana",
		state_fips: "22",
	},
	{
		name: "Maine",
		abbreviation: "ME",
		path: "maine",
		state_fips: "23",
	},
	{
		name: "Maryland",
		abbreviation: "MD",
		path: "maryland",
		state_fips: "24",
	},
	{
		name: "Massachusetts",
		abbreviation: "MA",
		path: "massachusetts",
		state_fips: "25",
	},
	{
		name: "Michigan",
		abbreviation: "MI",
		path: "michigan",
		state_fips: "26",
	},
	{
		name: "Minnesota",
		abbreviation: "MN",
		path: "minnesota",
		state_fips: "27",
	},
	{
		name: "Mississippi",
		abbreviation: "MS",
		path: "mississippi",
		state_fips: "28",
	},
	{
		name: "Missouri",
		abbreviation: "MO",
		path: "missouri",
		state_fips: "29",
	},
	{
		name: "Montana",
		abbreviation: "MT",
		path: "montana",
		state_fips: "30",
	},
	{
		name: "Nebraska",
		abbreviation: "NE",
		path: "nebraska",
		state_fips: "31",
	},
	{
		name: "Nevada",
		abbreviation: "NV",
		path: "nevada",
		state_fips: "32",
	},
	{
		name: "New Hampshire",
		abbreviation: "NH",
		path: "new-hampshire",
		state_fips: "33",
	},
	{
		name: "New Jersey",
		abbreviation: "NJ",
		path: "new-jersey",
		state_fips: "34",
	},
	{
		name: "New Mexico",
		abbreviation: "NM",
		path: "new-mexico",
		state_fips: "35",
	},
	{
		name: "New York",
		abbreviation: "NY",
		path: "new-york",
		state_fips: "36",
	},
	{
		name: "North Carolina",
		abbreviation: "NC",
		path: "north-carolina",
		state_fips: "37",
	},
	{
		name: "North Dakota",
		abbreviation: "ND",
		path: "north-dakota",
		state_fips: "38",
	},
	{
		name: "Ohio",
		abbreviation: "OH",
		path: "ohio",
		state_fips: "39",
	},
	{
		name: "Oklahoma",
		abbreviation: "OK",
		path: "oklahoma",
		state_fips: "40",
	},
	{
		name: "Oregon",
		abbreviation: "OR",
		path: "oregon",
		state_fips: "41",
	},
	{
		name: "Pennsylvania",
		abbreviation: "PA",
		path: "pennsylvania",
		state_fips: "42",
	},
	{
		name: "Rhode Island",
		abbreviation: "RI",
		path: "rhode-island",
		state_fips: "44",
	},
	{
		name: "South Carolina",
		abbreviation: "SC",
		path: "south-carolina",
		state_fips: "45",
	},
	{
		name: "South Dakota",
		abbreviation: "SD",
		path: "south-dakota",
		state_fips: "46",
	},
	{
		name: "Tennessee",
		abbreviation: "TN",
		path: "tennessee",
		state_fips: "47",
	},
	{
		name: "Texas",
		abbreviation: "TX",
		path: "texas",
		state_fips: "48",
	},
	{
		name: "Utah",
		abbreviation: "UT",
		path: "utah",
		state_fips: "49",
	},
	{
		name: "Vermont",
		abbreviation: "VT",
		path: "vermont",
		state_fips: "50",
	},
	{
		name: "Virginia",
		abbreviation: "VA",
		path: "virginia",
		state_fips: "51",
	},
	{
		name: "Washington",
		abbreviation: "WA",
		path: "washington",
		state_fips: "53",
	},
	{
		name: "West Virginia",
		abbreviation: "WV",
		path: "west-virginia",
		state_fips: "54",
	},
	{
		name: "Wisconsin",
		abbreviation: "WI",
		path: "wisconsin",
		state_fips: "55",
	},
	{
		name: "Wyoming",
		abbreviation: "WY",
		path: "wyoming",
		state_fips: "56",
	},
];

export const generateTrendsSeoTables = ({
	data,
	variant,
	state,
	county,
	city,
}: {
	data: TrendsData[];
	variant: "us" | "state" | "county" | "city";
	state?: string;
	county?: string;
	city?: string;
}) => {
	const nameSuffix = (() => {
		if (variant === "state") {
			return `${state}`;
		} else if (variant === "county") {
			return `${county} County, ${state}`;
		} else if (variant === "city") {
			return `${city}, ${county} County, ${state}`;
		}

		return "the United States";
	})();

	const descriptionSuffix = (() => {
		if (variant === "state") {
			return `for each county in ${state}`;
		} else if (variant === "county") {
			return `for each city in ${county} County, ${state}`;
		} else if (variant === "city") {
			return `for each zip code in ${city}, ${state}`;
		}

		return "for each state in the United States";
	})();

	const variantColumnDescriptions = (() => {
		if (variant === "state") {
			return [
				{
					name: "County",
					ref: "county",
					datatype: "string",
				},
			];
		} else if (variant === "county") {
			return [
				{
					name: "City",
					ref: "city",
					datatype: "string",
				},
			];
		} else if (variant === "city") {
			return [
				{
					name: "Zip Code",
					ref: "zip",
					datatype: "string",
				},
			];
		}

		return [
			{
				name: "State",
				ref: "state_name",
				datatype: "string",
			},
		];
	})();

	const variantDataColumns = (data: TrendsData) => {
		if (variant === "us") {
			return {
				state_name: data.state_name,
			};
		} else if (variant === "state") {
			return {
				county: data.county,
			};
		} else if (variant === "county") {
			return {
				city: data.city,
			};
		} else if (variant === "city") {
			return {
				zip: data.zip,
			};
		}
	};

	const seoTaxBillsTable: TrendsSEOTable = {
		name: `Property Tax Bills Across ${nameSuffix}`,
		description: `The table below showcases the 10th, 25th, 50th, 75th, and 90th percentiles of actual property tax paid on residential properties ${descriptionSuffix}. It's important to understand that property tax rates can vary greatly and can change yearly.`,
		columns: [
			...variantColumnDescriptions,
			{
				name: "10th Percentile",
				ref: "tax_bill_10th_percentile",
				datatype: "string",
			},
			{
				name: "25th Percentile",
				ref: "tax_bill_25th_percentile",
				datatype: "string",
			},
			{
				name: "Median Tax Bill",
				ref: "median_tax_bill",
				datatype: "string",
			},
			{
				name: "75th Percentile",
				ref: "tax_bill_75th_percentile",
				datatype: "string",
			},
			{
				name: "90th Percentile",
				ref: "tax_bill_90th_percentile",
				datatype: "string",
			},
		],
		data: data.map(row => ({
			...variantDataColumns(row),
			tax_bill_10th_percentile: row.tax_bill_10th_percentile,
			tax_bill_25th_percentile: row.tax_bill_25th_percentile,
			median_tax_bill: row.median_tax_bill,
			tax_bill_75th_percentile: row.tax_bill_75th_percentile,
			tax_bill_90th_percentile: row.tax_bill_90th_percentile,
		})),
	};

	const seoMortgageRateTable: TrendsSEOTable = {
		name: `Mortgage Rates Across ${nameSuffix}`,
		description: `The table below showcases the 10th, 25th, 50th, 75th, and 90th percentiles of mortgage rates ${descriptionSuffix}. It's important to understand that mortgage rates can vary greatly and can change yearly.`,
		columns: [
			...variantColumnDescriptions,
			{
				name: "10th Percentile",
				ref: "median_mortgage_rate_10th_percentile",
				datatype: "string",
			},
			{
				name: "25th Percentile",
				ref: "median_mortgage_rate_25th_percentile",
				datatype: "string",
			},
			{
				name: "Median Mortgage Rate",
				ref: "median_mortgage_rate",
				datatype: "string",
			},
			{
				name: "75th Percentile",
				ref: "median_mortgage_rate_75th_percentile",
				datatype: "string",
			},
			{
				name: "90th Percentile",
				ref: "median_mortgage_rate_90th_percentile",
				datatype: "string",
			},
		],
		data: data.map(row => ({
			...variantDataColumns(row),
			median_mortgage_rate_10th_percentile:
				row.median_mortgage_rate_10th_percentile,
			median_mortgage_rate_25th_percentile:
				row.median_mortgage_rate_25th_percentile,
			median_mortgage_rate: row.median_mortgage_rate,
			median_mortgage_rate_75th_percentile:
				row.median_mortgage_rate_75th_percentile,
			median_mortgage_rate_90th_percentile:
				row.median_mortgage_rate_90th_percentile,
		})),
	};

	const seoTaxRateTable: TrendsSEOTable = {
		name: `Property Tax Rates Across ${nameSuffix}`,
		description: `The table below showcases the 10th, 25th, 50th, 75th, and 90th percentiles of property tax rates ${descriptionSuffix}. It's important to understand that tax rates can vary greatly and can change yearly.`,
		columns: [
			...variantColumnDescriptions,
			{
				name: "10th Percentile",
				ref: "tax_rate_10th_percentile",
				datatype: "string",
			},
			{
				name: "25th Percentile",
				ref: "tax_rate_25th_percentile",
				datatype: "string",
			},
			{
				name: "Median Tax Rate",
				ref: "median_tax_rate",
				datatype: "string",
			},
			{
				name: "75th Percentile",
				ref: "tax_rate_75th_percentile",
				datatype: "string",
			},
			{
				name: "90th Percentile",
				ref: "tax_rate_90th_percentile",
				datatype: "string",
			},
		],
		data: data.map(row => ({
			...variantDataColumns(row),
			tax_rate_10th_percentile: row.tax_rate_10th_percentile,
			tax_rate_25th_percentile: row.tax_rate_25th_percentile,
			median_tax_rate: row.median_tax_rate,
			tax_rate_75th_percentile: row.tax_rate_75th_percentile,
			tax_rate_90th_percentile: row.tax_rate_90th_percentile,
		})),
	};

	const seoAssessedPropertyValuesTable: TrendsSEOTable = {
		name: `Property Tax Assessment Values Across ${nameSuffix}`,
		description: `The table below showcases the 10th, 25th, 50th, 75th, and 90th percentiles of assessed property values ${descriptionSuffix}. It's important to understand that assessed property values can vary greatly and can change yearly.`,
		columns: [
			...variantColumnDescriptions,
			{
				name: "10th Percentile",
				ref: "home_price_10th_percentile",
				datatype: "string",
			},
			{
				name: "25th Percentile",
				ref: "home_price_25th_percentile",
				datatype: "string",
			},
			{
				name: "Median Assessed Value",
				ref: "median_home_price",
				datatype: "string",
			},
			{
				name: "75th Percentile",
				ref: "home_price_75th_percentile",
				datatype: "string",
			},
			{
				name: "90th Percentile",
				ref: "home_price_90th_percentile",
				datatype: "string",
			},
		],
		data: data.map(row => ({
			...variantDataColumns(row),
			home_price_10th_percentile: row.home_price_10th_percentile,
			home_price_25th_percentile: row.home_price_25th_percentile,
			median_home_price: row.median_home_price,
			home_price_75th_percentile: row.home_price_75th_percentile,
			home_price_90th_percentile: row.home_price_90th_percentile,
		})),
	};

	const seoTotalHomesSoldTable: TrendsSEOTable = {
		name: `Property Sales Across ${nameSuffix}`,
		description: `The table below showcases the total number of homes sold ${descriptionSuffix}. It's important to understand that the number of homes sold can vary greatly and can change yearly.`,
		columns: [
			...variantColumnDescriptions,
			{
				name: "Total Sales",
				ref: "total_sales",
				datatype: "string",
			},
		],
		data: data.map(row => ({
			...variantDataColumns(row),
			total_sales: row.total_sales,
		})),
	};

	return {
		seoTaxBillsTable,
		seoMortgageRateTable,
		seoTaxRateTable,
		seoAssessedPropertyValuesTable,
		seoTotalHomesSoldTable,
	};
};

export const stateExemptionTypes: Record<
	string,
	{
		senior: boolean;
		disabled: boolean;
		homestead: boolean;
		veteran: boolean;
		solarOrWind: boolean;
		charitable: boolean;
		lowIncome: boolean;
	}
> = {
	AL: {
		senior: true,
		disabled: true,
		homestead: true,
		veteran: true,
		solarOrWind: false,
		charitable: true,
		lowIncome: false,
	},
	AK: {
		senior: true,
		disabled: false,
		homestead: true,
		veteran: true,
		solarOrWind: false,
		charitable: true,
		lowIncome: false,
	},
	AZ: {
		senior: false,
		disabled: true,
		homestead: false,
		veteran: false,
		solarOrWind: false,
		charitable: true,
		lowIncome: false,
	},
	AR: {
		senior: true,
		disabled: true,
		homestead: true,
		veteran: true,
		solarOrWind: false,
		charitable: true,
		lowIncome: false,
	},
	CA: {
		senior: false,
		disabled: true,
		homestead: true,
		veteran: false,
		solarOrWind: true,
		charitable: true,
		lowIncome: false,
	},
	CO: {
		senior: true,
		disabled: true,
		homestead: false,
		veteran: true,
		solarOrWind: false,
		charitable: true,
		lowIncome: false,
	},
	CT: {
		senior: true,
		disabled: true,
		homestead: false,
		veteran: true,
		solarOrWind: false,
		charitable: true,
		lowIncome: false,
	},
	DC: {
		senior: true,
		disabled: true,
		homestead: true,
		veteran: true,
		solarOrWind: false,
		charitable: true,
		lowIncome: false,
	},
	DE: {
		senior: true,
		disabled: true,
		homestead: false,
		veteran: false,
		solarOrWind: false,
		charitable: true,
		lowIncome: false,
	},
	FL: {
		senior: true,
		disabled: true,
		homestead: true,
		veteran: true,
		solarOrWind: true,
		charitable: true,
		lowIncome: false,
	},
	GA: {
		senior: true,
		disabled: true,
		homestead: true,
		veteran: true,
		solarOrWind: false,
		charitable: true,
		lowIncome: false,
	},
	HI: {
		senior: true,
		disabled: true,
		homestead: true,
		veteran: true,
		solarOrWind: true,
		charitable: true,
		lowIncome: false,
	},
	ID: {
		senior: true,
		disabled: true,
		homestead: true,
		veteran: true,
		solarOrWind: false,
		charitable: true,
		lowIncome: false,
	},
	IL: {
		senior: true,
		disabled: true,
		homestead: true,
		veteran: true,
		solarOrWind: true,
		charitable: true,
		lowIncome: false,
	},
	IN: {
		senior: true,
		disabled: true,
		homestead: true,
		veteran: true,
		solarOrWind: true,
		charitable: true,
		lowIncome: false,
	},
	IA: {
		senior: true,
		disabled: true,
		homestead: true,
		veteran: true,
		solarOrWind: true,
		charitable: true,
		lowIncome: true,
	},
	KS: {
		senior: true,
		disabled: true,
		homestead: false,
		veteran: false,
		solarOrWind: false,
		charitable: true,
		lowIncome: false,
	},
	KY: {
		senior: true,
		disabled: true,
		homestead: true,
		veteran: true,
		solarOrWind: true,
		charitable: true,
		lowIncome: false,
	},
	LA: {
		senior: true,
		disabled: true,
		homestead: true,
		veteran: true,
		solarOrWind: false,
		charitable: true,
		lowIncome: true,
	},
	ME: {
		senior: false,
		disabled: true,
		homestead: true,
		veteran: true,
		solarOrWind: true,
		charitable: true,
		lowIncome: false,
	},
	MD: {
		senior: false,
		disabled: true,
		homestead: true,
		veteran: true,
		solarOrWind: true,
		charitable: true,
		lowIncome: false,
	},
	MA: {
		senior: true,
		disabled: true,
		homestead: false,
		veteran: true,
		solarOrWind: true,
		charitable: true,
		lowIncome: false,
	},
	MI: {
		senior: true,
		disabled: true,
		homestead: true,
		veteran: true,
		solarOrWind: false,
		charitable: true,
		lowIncome: false,
	},
	MN: {
		senior: true,
		disabled: true,
		homestead: true,
		veteran: true,
		solarOrWind: true,
		charitable: true,
		lowIncome: true,
	},
	MS: {
		senior: true,
		disabled: true,
		homestead: true,
		veteran: false,
		solarOrWind: false,
		charitable: false,
		lowIncome: false,
	},
	MO: {
		senior: true,
		disabled: true,
		homestead: false,
		veteran: true,
		solarOrWind: true,
		charitable: true,
		lowIncome: false,
	},
	MT: {
		senior: true,
		disabled: true,
		homestead: true,
		veteran: true,
		solarOrWind: true,
		charitable: true,
		lowIncome: false,
	},
	NE: {
		senior: true,
		disabled: true,
		homestead: true,
		veteran: true,
		solarOrWind: false,
		charitable: true,
		lowIncome: false,
	},
	NV: {
		senior: false,
		disabled: true,
		homestead: true,
		veteran: true,
		solarOrWind: true,
		charitable: true,
		lowIncome: false,
	},
	NH: {
		senior: true,
		disabled: true,
		homestead: true,
		veteran: true,
		solarOrWind: true,
		charitable: true,
		lowIncome: false,
	},
	NJ: {
		senior: true,
		disabled: true,
		homestead: true,
		veteran: true,
		solarOrWind: true,
		charitable: true,
		lowIncome: false,
	},
	NM: {
		senior: false,
		disabled: false,
		homestead: false,
		veteran: false,
		solarOrWind: false,
		charitable: false,
		lowIncome: false,
	},
	NY: {
		senior: true,
		disabled: true,
		homestead: true,
		veteran: true,
		solarOrWind: true,
		charitable: true,
		lowIncome: false,
	},
	NC: {
		senior: true,
		disabled: true,
		homestead: true,
		veteran: true,
		solarOrWind: true,
		charitable: true,
		lowIncome: false,
	},
	ND: {
		senior: true,
		disabled: true,
		homestead: false,
		veteran: true,
		solarOrWind: false,
		charitable: false,
		lowIncome: true,
	},
	OH: {
		senior: true,
		disabled: true,
		homestead: true,
		veteran: true,
		solarOrWind: true,
		charitable: true,
		lowIncome: false,
	},
	OK: {
		senior: true,
		disabled: true,
		homestead: true,
		veteran: true,
		solarOrWind: false,
		charitable: true,
		lowIncome: false,
	},
	OR: {
		senior: false,
		disabled: true,
		homestead: false,
		veteran: true,
		solarOrWind: true,
		charitable: true,
		lowIncome: false,
	},
	PA: {
		senior: false,
		disabled: false,
		homestead: true,
		veteran: true,
		solarOrWind: false,
		charitable: false,
		lowIncome: false,
	},
	RI: {
		senior: true,
		disabled: true,
		homestead: true,
		veteran: true,
		solarOrWind: false,
		charitable: false,
		lowIncome: false,
	},
	SC: {
		senior: true,
		disabled: true,
		homestead: true,
		veteran: true,
		solarOrWind: false,
		charitable: false,
		lowIncome: false,
	},
	SD: {
		senior: true,
		disabled: true,
		homestead: true,
		veteran: true,
		solarOrWind: true,
		charitable: true,
		lowIncome: false,
	},
	TN: {
		senior: false,
		disabled: false,
		homestead: false,
		veteran: false,
		solarOrWind: false,
		charitable: true,
		lowIncome: false,
	},
	TX: {
		senior: true,
		disabled: true,
		homestead: true,
		veteran: true,
		solarOrWind: true,
		charitable: true,
		lowIncome: false,
	},
	UT: {
		senior: true,
		disabled: true,
		homestead: false,
		veteran: true,
		solarOrWind: false,
		charitable: true,
		lowIncome: false,
	},
	VT: {
		senior: false,
		disabled: true,
		homestead: false,
		veteran: true,
		solarOrWind: false,
		charitable: false,
		lowIncome: false,
	},
	VA: {
		senior: true,
		disabled: true,
		homestead: false,
		veteran: true,
		solarOrWind: true,
		charitable: true,
		lowIncome: false,
	},
	WA: {
		senior: true,
		disabled: true,
		homestead: false,
		veteran: true,
		solarOrWind: false,
		charitable: true,
		lowIncome: false,
	},
	WV: {
		senior: true,
		disabled: true,
		homestead: false,
		veteran: false,
		solarOrWind: false,
		charitable: false,
		lowIncome: false,
	},
	WI: {
		senior: false,
		disabled: false,
		homestead: true,
		veteran: false,
		solarOrWind: false,
		charitable: false,
		lowIncome: false,
	},
	WY: {
		senior: false,
		disabled: false,
		homestead: false,
		veteran: true,
		solarOrWind: false,
		charitable: false,
		lowIncome: false,
	},
};

export const formatStatePathSegment = (state_name: string) => {
	return state_name.replace(/\s+/g, "-").toLowerCase();
};

export const formatStateLink = (state_name: string) => {
	return `/trends/${formatStatePathSegment(state_name)}`;
};

export const formatCountyPathSegment = (county: string) => {
	return `${county
		.replace(/-/g, "_")
		?.replace(/\s+/g, "-")
		.toLowerCase()}-county`;
};

export const formatCountyLink = ({
	state_name,
	county,
}: {
	state_name: string;
	county: string;
}) => {
	return formatStateLink(state_name) + `/${formatCountyPathSegment(county)}`;
};

export const formatCityPathSegment = (city: string) => {
	return city.replace(/\s+/g, "-").toLowerCase();
};

export const formatCityLink = ({
	state_name,
	county,
	city,
}: {
	state_name: string;
	county: string;
	city: string;
}) => {
	return (
		formatCountyLink({ state_name, county }) + `/${formatCityPathSegment(city)}`
	);
};
